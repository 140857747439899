module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_esli_pr72qmdyhlv4zu43jziejow6k4/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withAvif":true,"withWebp":true,"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.97.1/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/buildhome/repo/src/cms/cms.ts","enableIdentityWidget":true,"htmlTitle":"CMS | The Leaky Cauldron Blog"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@_ot3c2ycmavvgueerzwpiojb3ra/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://theleakycauldronblog.com","trailingSlash":"always","i18nextOptions":{"defaultNS":"common","debug":false,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18._btpwbdiok5vqgbniexmdgi3rqi/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ffa3d7","showSpinner":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_wfnilexbbt7sx3cfp35a225x2q/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Leaky Cauldron Blog","short_name":"tlcb","start_url":"/","background_color":"#ffffff","theme_color":"#020817","display":"standalone","icons":[{"src":"/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/icon-512.png","sizes":"512x512","type":"image/png"},{"src":"/icon-192-maskable.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"/icon-512-maskable.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"/apple-touch-icon.png","sizes":"192x192","type":"image/png"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-twitter@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3._lfo6gogygzxpsb53xx7evmv3ca/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_kskercexmofssu5zbws5uknkvu/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__oxdhu3znfiejr5yxcwpahwwywm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
